import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Button, 
  IconButton, 
  Box, 
  CssBaseline,
  Snackbar,
  Alert,
  CircularProgress,
  Grid,
  TextField,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { Menu as MenuIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import PhotoboothCard from './PhotoboothCard';
import './PhotoboothCard.css';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';

const API_BASE_URL = 'https://photoboothmonitor.laboitedusmile.fr/api';
const WS_BASE_URL = 'wss://photoboothmonitor.laboitedusmile.fr/api/ws';

const Dashboard = () => {
    const [photobooths, setPhotobooths] = useState([]);
    const [filteredPhotobooths, setFilteredPhotobooths] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [filter, setFilter] = useState('all');
    const [sort, setSort] = useState('name');
    const [searchTerm, setSearchTerm] = useState('');
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [photoboothToDelete, setPhotoboothToDelete] = useState(null);
    const navigate = useNavigate();
    const wsRef = useRef(null);

    const isTokenExpired = (token) => {
        const payload = JSON.parse(atob(token.split('.')[1]));
        return Date.now() >= payload.exp * 1000;
    };

    const applyFilterAndSort = useCallback(() => {
        let result = [...photobooths];

        if (filter === 'online') {
            result = result.filter(pb => pb.online);
        } else if (filter === 'offline') {
            result = result.filter(pb => !pb.online);
        }

        if (searchTerm) {
            result = result.filter(pb => pb.name.toLowerCase().includes(searchTerm.toLowerCase()));
        }

        result.sort((a, b) => {
            if (sort === 'name') {
                return a.name.localeCompare(b.name);
            } else if (sort === 'status') {
                return (b.online ? 1 : 0) - (a.online ? 1 : 0);
            }
            return 0;
        });

        setFilteredPhotobooths(result);
    }, [photobooths, filter, sort, searchTerm]);

    useEffect(() => {
        applyFilterAndSort();
    }, [applyFilterAndSort]);

    const updatePhotobooths = useCallback((updatedPhotobooths) => {
        if (Array.isArray(updatedPhotobooths)) {
            setPhotobooths(updatedPhotobooths);
        } else {
            console.error('Expected an array of photobooths, but received:', updatedPhotobooths);
        }
    }, []);

    const updateSinglePhotobooth = useCallback((updatedPhotobooth) => {
        setPhotobooths(prevPhotobooths => 
            prevPhotobooths.map(pb => 
                pb.name === updatedPhotobooth.name ? { ...pb, ...updatedPhotobooth } : pb
            )
        );
    }, []);

    const resetFilters = useCallback(() => {
        setFilter('all');
        setSort('name');
        setSearchTerm('');
    }, []);

    const fetchPhotobooths = useCallback(async (token) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${API_BASE_URL}/photobooths`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            if (Array.isArray(response.data)) {
                updatePhotobooths(response.data);
            } else {
                throw new Error('Format inattendu des données reçues');
            }
        } catch (error) {
            setErrorMessage(`Erreur lors de la récupération des données : ${error.message}`);
            setOpenSnackbar(true);
            updatePhotobooths([]);
        } finally {
            setIsLoading(false);
        }
    }, [updatePhotobooths]);

    const handleDeletePhotobooth = useCallback((name) => {
        setPhotoboothToDelete(name);
        setDeleteConfirmOpen(true);
    }, []);

    const confirmDeletePhotobooth = useCallback(async () => {
        if (!photoboothToDelete) return;

        try {
            const token = localStorage.getItem('token');
            await axios.delete(`${API_BASE_URL}/photobooths/${photoboothToDelete}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            setPhotobooths(prevPhotobooths => prevPhotobooths.filter(pb => pb.name !== photoboothToDelete));
        } catch (error) {
            setErrorMessage(`Erreur lors de la suppression : ${error.message}`);
            setOpenSnackbar(true);
        } finally {
            setDeleteConfirmOpen(false);
            setPhotoboothToDelete(null);
        }
    }, [photoboothToDelete]);

    const connectWebSocket = useCallback((token) => {
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
            console.log('WebSocket déjà connecté');
            return;
        }

        wsRef.current = new WebSocket(`${WS_BASE_URL}/frontend?token=${token}`);

        wsRef.current.onopen = () => {
            console.log('Connexion WebSocket Photobooth établie.');
        };

        wsRef.current.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                console.log('Message WebSocket reçu:', data);
                
                if (data.type === 'photobooth_deleted') {
                    setPhotobooths(prevPhotobooths => prevPhotobooths.filter(pb => pb.name !== data.name));
                } else if (data.name) {
                    updateSinglePhotobooth(data);
                } else {
                    console.error('Format de message WebSocket non reconnu:', data);
                }
            } catch (error) {
                console.error('Erreur lors du parsing du message WebSocket:', error);
            }
        };

        wsRef.current.onclose = (event) => {
            console.log(`WebSocket fermé avec le code: ${event.code}`);
            if (event.code !== 1000) {
                setTimeout(() => connectWebSocket(token), 5000);
            }
        };

        wsRef.current.onerror = (error) => {
            console.error('Erreur WebSocket:', error);
            setErrorMessage('Erreur de connexion WebSocket. Tentative de reconnexion...');
            setOpenSnackbar(true);
        };

    }, [updateSinglePhotobooth]);

    const handleLogout = useCallback(() => {
        localStorage.removeItem('token');
        if (wsRef.current) {
            wsRef.current.close(1000, 'Logout');
        }
        navigate('/login');
    }, [navigate]);

    const handleSnackbarClose = useCallback(() => {
        setOpenSnackbar(false);
    }, []);

    const handleRefresh = useCallback(() => {
        const token = localStorage.getItem('token');
        if (token && !isTokenExpired(token)) {
            fetchPhotobooths(token);
        }
    }, [fetchPhotobooths]);

    useEffect(() => {
        const token = localStorage.getItem('token');
    
        if (!token || isTokenExpired(token)) {
            console.log('Pas de token ou token expiré, redirection vers login');
            navigate('/login');
        } else {
            fetchPhotobooths(token);
            connectWebSocket(token);
        }

        return () => {
            if (wsRef.current) {
                wsRef.current.close(1000, 'Component unmounting');
            }
        };
    }, [navigate, connectWebSocket, fetchPhotobooths]);

    const monitorCPUTemperature = useCallback((name, temperature) => {
        if (temperature > 80) {
            setErrorMessage(`Attention : La température CPU de ${name} est élevée (${temperature}°C)`);
            setOpenSnackbar(true);
        }
    }, []);

    const renderPhotoboothCards = useCallback(() => {
        return (
            <Grid container spacing={3} justifyContent="center">
                {filteredPhotobooths.map(photobooth => (
                    <Grid item xs={12} sm={6} md={4} key={photobooth.name} 
                          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <PhotoboothCard
                            {...photobooth}
                            onDeletePhotobooth={handleDeletePhotobooth}
                            onTemperatureWarning={(temp) => monitorCPUTemperature(photobooth.name, temp)}
                        />
                    </Grid>
                ))}
            </Grid>
        );
    }, [filteredPhotobooths, handleDeletePhotobooth, monitorCPUTemperature]);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', width: '100%' }}>
                <AppBar position="static" sx={{ width: '100%', margin: 0, padding: 0 }}>
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            Dashboard
                        </Typography>
                        <Button color="inherit" onClick={handleRefresh} startIcon={<RefreshIcon />}>
                            Rafraîchir
                        </Button>
                        <Button color="inherit" onClick={handleLogout}>
                            Logout
                        </Button>
                    </Toolbar>
                </AppBar>
                
                <Box sx={{ p: 2 }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={3}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Rechercher"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Select
                                fullWidth
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                            >
                                <MenuItem value="all">Tous</MenuItem>
                                <MenuItem value="online">En ligne</MenuItem>
                                <MenuItem value="offline">Hors ligne</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Select
                                fullWidth
                                value={sort}
                                onChange={(e) => setSort(e.target.value)}
                            >
                                <MenuItem value="name">Trier par nom</MenuItem>
                                <MenuItem value="status">Trier par statut</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Button 
                                onClick={resetFilters} 
                                variant="outlined" 
                                color="secondary"
                                fullWidth
                            >
                                Réinitialiser les filtres
                            </Button>
                        </Grid>
                    </Grid>
                </Box>

                <Box component="main" sx={{ flexGrow: 1, p: 3, width: '100%' }}>
                    {isLoading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                            <CircularProgress color="secondary" />
                        </Box>
                    ) : (
                        renderPhotoboothCards()
                    )}
                </Box>
            </Box>

            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>

            <Dialog
                open={deleteConfirmOpen}
                onClose={() => setDeleteConfirmOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirmer la suppression"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Êtes-vous sûr de vouloir supprimer le photobooth {photoboothToDelete} ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteConfirmOpen(false)}>Annuler</Button>
                    <Button onClick={confirmDeletePhotobooth} autoFocus>
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
};

export default Dashboard;