// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Pour appliquer un fond personnalis� lors du remplissage automatique */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #333333 inset !important; /* Fond personnalis� */
  -webkit-text-fill-color: #e0e0e0 !important; /* Couleur du texte */
  border: 2px solid #90caf9 !important; /* Bordure bleue */
}

/* Effet en hover et focus pour les champs autofill */
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px #333333 inset !important;
  -webkit-text-fill-color: #e0e0e0 !important;
  border: 2px solid #90caf9 !important; /* Couleur de la bordure lors du focus */
}

/* Force la couleur du label lorsqu'il est en focus */
input:focus + label,
label.Mui-focused {
  color: #90caf9 !important; /* Assure que le label reste bleu lors du focus */
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA,wEAAwE;AACxE;EACE,uDAAuD,EAAE,sBAAsB;EAC/E,2CAA2C,EAAE,qBAAqB;EAClE,oCAAoC,EAAE,kBAAkB;AAC1D;;AAEA,qDAAqD;AACrD;;EAEE,uDAAuD;EACvD,2CAA2C;EAC3C,oCAAoC,EAAE,wCAAwC;AAChF;;AAEA,qDAAqD;AACrD;;EAEE,yBAAyB,EAAE,iDAAiD;AAC9E","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n/* Pour appliquer un fond personnalis� lors du remplissage automatique */\ninput:-webkit-autofill {\n  -webkit-box-shadow: 0 0 0 30px #333333 inset !important; /* Fond personnalis� */\n  -webkit-text-fill-color: #e0e0e0 !important; /* Couleur du texte */\n  border: 2px solid #90caf9 !important; /* Bordure bleue */\n}\n\n/* Effet en hover et focus pour les champs autofill */\ninput:-webkit-autofill:hover, \ninput:-webkit-autofill:focus {\n  -webkit-box-shadow: 0 0 0 30px #333333 inset !important;\n  -webkit-text-fill-color: #e0e0e0 !important;\n  border: 2px solid #90caf9 !important; /* Couleur de la bordure lors du focus */\n}\n\n/* Force la couleur du label lorsqu'il est en focus */\ninput:focus + label,\nlabel.Mui-focused {\n  color: #90caf9 !important; /* Assure que le label reste bleu lors du focus */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
