// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-container {
    background-color: #333;
    color: #fff;
    min-height: 100vh;
    padding: 20px;
    font-family: Arial, sans-serif;
    width: 100%; /* Prendre toute la largeur */
    box-sizing: border-box;
}

.photobooth-cards {
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between; /* �galiser l'espace entre les cartes */
    width: 100%; /* Assurer que le conteneur prend toute la largeur */
}

.photobooth-card {
    background-color: #444;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #555;
    width: calc(33.33% - 40px); /* Ajuster la largeur en fonction de l'espace disponible */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,WAAW;IACX,iBAAiB;IACjB,aAAa;IACb,8BAA8B;IAC9B,WAAW,EAAE,6BAA6B;IAC1C,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,SAAS;IACT,8BAA8B,EAAE,uCAAuC;IACvE,WAAW,EAAE,oDAAoD;AACrE;;AAEA;IACI,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,sBAAsB;IACtB,0BAA0B,EAAE,0DAA0D;IACtF,wCAAwC;IACxC,sBAAsB;AAC1B","sourcesContent":[".app-container {\n    background-color: #333;\n    color: #fff;\n    min-height: 100vh;\n    padding: 20px;\n    font-family: Arial, sans-serif;\n    width: 100%; /* Prendre toute la largeur */\n    box-sizing: border-box;\n}\n\n.photobooth-cards {\n    flex-wrap: wrap;\n    gap: 20px;\n    justify-content: space-between; /* �galiser l'espace entre les cartes */\n    width: 100%; /* Assurer que le conteneur prend toute la largeur */\n}\n\n.photobooth-card {\n    background-color: #444;\n    padding: 20px;\n    border-radius: 8px;\n    border: 1px solid #555;\n    width: calc(33.33% - 40px); /* Ajuster la largeur en fonction de l'espace disponible */\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);\n    box-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
