import axios from 'axios';

const API_URL = 'https://photoboothmonitor.laboitedusmile.fr/api';

export const refreshToken = async () => {
  const currentToken = localStorage.getItem('token');
  try {
    const response = await axios.post(`${API_URL}/auth/refresh-token`, { token: currentToken });
    const { token } = response.data;
    localStorage.setItem('token', token);
    return token;
  } catch (error) {
    console.error('Error refreshing token:', error);
    localStorage.removeItem('token');
    window.location.href = '/login'; // Redirection vers la page de login en cas d'�chec
  }
};

export const setupAxiosInterceptors = (navigate) => {
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (error.response && error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          const newToken = await refreshToken();
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + newToken;
          return axios(originalRequest); // R�essayer la requ�te originale avec le nouveau token
        } catch (refreshError) {
          navigate('/login'); // Rediriger vers la page de login si la tentative de rafra�chir le token �choue
        }
      }
      return Promise.reject(error);
    }
  );
};
