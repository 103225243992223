import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#1a1b2e',
      paper: '#222b45',
    },
    text: {
      primary: '#ffffff',
      secondary: '#8f9bb3',
    },
    primary: {
      main: '#3366ff',
    },
    secondary: {
      main: '#0095ff',
    },
    error: {
      main: '#ff3d71',
    },
    success: {
      main: '#00d68f',
    },
    warning: {
      main: '#ffaa00',
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#222b45',
          borderRadius: '0.5rem',
          '&.photobooth-card': {
            width: '250px',
            padding: '16px',
            display: 'flex',
            flexDirection: 'column',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '0.25rem',
        },
        contained: {
          boxShadow: 'none',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#1a1f33',
          '&:hover': {
            backgroundColor: '#151a30',
          },
          '&.Mui-focused': {
            backgroundColor: '#151a30',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3366ff',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#598bff',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#598bff',
          },
        },
      },
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: { fontSize: '2.5rem', fontWeight: 500 },
    h2: { fontSize: '2rem', fontWeight: 500 },
    h3: { fontSize: '1.75rem', fontWeight: 500 },
    h4: { fontSize: '1.5rem', fontWeight: 500 },
    h5: { fontSize: '1.25rem', fontWeight: 500 },
    h6: { fontSize: '1rem', fontWeight: 500 },
  },
});

export default theme;