import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { 
  Box, 
  Typography, 
  Grid, 
  CircularProgress,
  Alert,
  Card,
  CardContent,
  Button
} from '@mui/material';
import { 
  LineChart, 
  Line, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend, 
  ResponsiveContainer 
} from 'recharts';

const ITEMS_PER_PAGE = 50;

const ScreenshotViewer = React.lazy(() => import('./ScreenshotViewer'));

const Photobooth = () => {
  const { name } = useParams();
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const fetchHistoryData = useCallback(async (pageNum) => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError("Pas de token d'authentification trouvé");
      return [];
    }
    try {
      const response = await axios.get(`/api/photobooth/${name}/history`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { page: pageNum, limit: ITEMS_PER_PAGE }
      });
      return response.data;
    } catch (error) {
      console.error('Erreur lors de la récupération des données d\'historique:', error);
      return [];
    }
  }, [name]);

  const loadMoreData = useCallback(async () => {
    if (!hasMore) return;
    const newData = await fetchHistoryData(page);
    if (newData.length < ITEMS_PER_PAGE) {
      setHasMore(false);
    }
    setHistoryData(prevData => [...prevData, ...newData]);
    setPage(prevPage => prevPage + 1);
  }, [fetchHistoryData, page, hasMore]);

  useEffect(() => {
    const initializeData = async () => {
      setLoading(true);
      const initialHistoryData = await fetchHistoryData(1);
      setHistoryData(initialHistoryData);
      setPage(2);
      setLoading(false);
    };

    initializeData();
  }, [fetchHistoryData]);

  const formatDate = useCallback((dateString) => {
    return new Date(dateString).toLocaleString('fr-FR', {
      hour: '2-digit',
      minute: '2-digit'
    });
  }, []);

  const memoizedCharts = useMemo(() => (
    <>
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>Utilisation CPU (%)</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={historyData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="timestamp" 
                  tickFormatter={formatDate}
                  allowDataOverflow={false}
                  allowDecimals={true}
                  allowDuplicatedCategory={true}
                />
                <YAxis
                  allowDataOverflow={false}
                  allowDecimals={true}
                  domain={[0, 'auto']}
                />
                <Tooltip labelFormatter={formatDate} />
                <Legend />
                <Line type="monotone" dataKey="cpu_usage" stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>Utilisation RAM (%)</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={historyData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="timestamp" 
                  tickFormatter={formatDate}
                  allowDataOverflow={false}
                  allowDecimals={true}
                  allowDuplicatedCategory={true}
                />
                <YAxis
                  allowDataOverflow={false}
                  allowDecimals={true}
                  domain={[0, 'auto']}
                />
                <Tooltip labelFormatter={formatDate} />
                <Legend />
                <Line type="monotone" dataKey="ram_usage" stroke="#82ca9d" />
              </LineChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>Température CPU (°C)</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={historyData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="timestamp" 
                  tickFormatter={formatDate}
                  allowDataOverflow={false}
                  allowDecimals={true}
                  allowDuplicatedCategory={true}
                />
                <YAxis
                  allowDataOverflow={false}
                  allowDecimals={true}
                  domain={[0, 'auto']}
                />
                <Tooltip labelFormatter={formatDate} />
                <Legend />
                <Line type="monotone" dataKey="cpu_temp" stroke="#ffc658" />
              </LineChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>
      </Grid>
    </>
  ), [historyData, formatDate]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={3}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>{name} - Détails</Typography>

      <Grid container spacing={3}>
        {memoizedCharts}

        <Grid item xs={12}>
          <React.Suspense fallback={<CircularProgress />}>
            <ScreenshotViewer name={name} />
          </React.Suspense>
        </Grid>
      </Grid>

      {hasMore && (
        <Box mt={3} display="flex" justifyContent="center">
          <Button variant="contained" onClick={loadMoreData}>
            Charger plus de données
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Photobooth;