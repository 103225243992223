import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Home() {
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login'); // Rediriger vers la page de connexion si l'utilisateur n'est pas authentifié
        }
    }, [navigate]);

    return (
        <div>
            <h1>Photobooth Monitor</h1>
            {/* Votre contenu de la page principale ici */}
        </div>
    );
}

export default Home;
