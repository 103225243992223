import React from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Cpu, HardDrive, Thermometer, MoreVertical } from 'lucide-react';
import { Card, CardContent, Typography, Button, Box, IconButton, Menu, MenuItem, Modal } from '@mui/material';
import './PhotoboothCard.css';
import { Link } from 'react-router-dom';

const PhotoboothCard = ({ name, online, data, first_seen, last_seen, screenshot, onStartDslrBooth, onDeletePhotobooth, onTemperatureWarning }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleMenuOpen = (event) => {
    if (online) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeletePhotobooth = () => {
    if (online) {
      onDeletePhotobooth(name);
      handleMenuClose();
    }
  };

  const handleOpen = () => {
    if (online) setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const extractUptime = (data) => {
    const uptimeMatch = data.match(/Uptime: ([\d\shm]+)/);
    return uptimeMatch ? uptimeMatch[1] : 'N/A';
  };

  const uptime = extractUptime(data);
  const cpuUsage = parseFloat(data.match(/CPU Usage: ([\d.]+)%/)[1]);
  const ramUsage = parseFloat(data.match(/RAM Usage: ([\d.]+)%/)[1]);
  const cpuTemp = parseFloat(data.match(/CPU Temp: ([\d.]+)°C/)[1]);

  React.useEffect(() => {
    if (online && cpuTemp > 80) {
      onTemperatureWarning(cpuTemp);
    }
  }, [online, cpuTemp, onTemperatureWarning]);

  const formatDate = (date) => {
    return new Date(date).toLocaleString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
  };

  const getConnectionText = () => {
    if (online) {
      return `Connecté depuis : ${first_seen ? formatDate(first_seen) : 'N/A'}`;
    } else {
      return `Dernière connexion : ${last_seen ? formatDate(last_seen) : 'N/A'}`;
    }
  };

  return (
    <>
      <Card className={`photobooth-card MuiCard-root ${online ? '' : 'offline-card'}`} sx={{ width: '100%', maxWidth: 700, minWidth: 400, height: 'auto', position: 'relative' }}>
        <IconButton 
          size="small" 
          className="more-button" 
          onClick={handleMenuOpen} 
          disabled={!online} 
          sx={{ 
            position: 'absolute', 
            top: '10px',  
            right: '10px',  
            zIndex: 2  
          }}
        >
          <MoreVertical size={18} />
        </IconButton>

        <CardContent>
          <Box display="flex" justifyContent="center" alignItems="center" mb={1}>
            <Typography 
              variant="h6" 
              className="card-title" 
              sx={{ 
                flexGrow: 1, 
                textAlign: 'center',  
                fontSize: '2rem',  
                fontWeight: 'bold' 
              }}>
              {name}
              <span className={`status-indicator ${online ? 'online' : 'offline'}`} style={{
                display: 'inline-block',
                marginLeft: 10,
                backgroundColor: online ? '#00d68f' : '#ff3d71',
                width: 12,
                height: 12,
                borderRadius: '50%',
              }}></span>
            </Typography>
          </Box>
          
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleDeletePhotobooth}>Supprimer</MenuItem>
          </Menu>
          
          <Typography variant="body2" className="card-subtitle">
            {getConnectionText()}
          </Typography>

          {online && (
            <Typography variant="body2" className="card-subtitle">
              Temps de fonctionnement : {uptime}
            </Typography>
          )}
          
          <Box className="metrics-container" sx={{ display: 'flex', justifyContent: 'space-between', marginY: 2 }}>
            <Box className="metric" sx={{ width: '25%' }}>
              <CircularProgressbarWithChildren
                value={cpuUsage}
                strokeWidth={10}
                styles={buildStyles({
                  rotation: 0.75,
                  strokeLinecap: 'butt',
                  trailColor: '#1a1f33',
                  pathColor: '#3366ff',
                })}
              >
                <Cpu size={14} color="#3366ff" />
                <div className="metric-value">{cpuUsage}%</div>
              </CircularProgressbarWithChildren>
              <Typography variant="caption" className="metric-label">Processeur</Typography>
            </Box>
            
            <Box className="metric" sx={{ width: '25%' }}>
              <CircularProgressbarWithChildren
                value={ramUsage}
                strokeWidth={10}
                styles={buildStyles({
                  rotation: 0.75,
                  strokeLinecap: 'butt',
                  trailColor: '#1a1f33',
                  pathColor: '#00d68f',
                })}
              >
                <HardDrive size={14} color="#00d68f" />
                <div className="metric-value">{ramUsage}%</div>
              </CircularProgressbarWithChildren>
              <Typography variant="caption" className="metric-label">RAM</Typography>
            </Box>
            
            <Box className="metric" sx={{ width: '25%' }}>
              <CircularProgressbarWithChildren
                value={cpuTemp}
                strokeWidth={10}
                styles={buildStyles({
                  rotation: 0.75,
                  strokeLinecap: 'butt',
                  trailColor: '#1a1f33',
                  pathColor: '#ffaa00',
                })}
              >
                <Thermometer size={14} color="#ffaa00" />
                <div className="metric-value">{cpuTemp}°C</div>
              </CircularProgressbarWithChildren>
              <Typography variant="caption" className="metric-label">Température</Typography>
            </Box>
          </Box>
          
          {screenshot ? (
            <Box 
              component="img"
              src={screenshot}
              alt={`Capture d'écran de ${name}`}
              className="screenshot"
              onClick={handleOpen}
              sx={{ width: '100%', height: 'auto', maxHeight: '300px', objectFit: 'contain', borderRadius: '5px', marginY: 2 }}
            />
          ) : (
            <Box className="screenshot-placeholder" sx={{ width: '100%', height: '250px', backgroundColor: '#1a1f33', borderRadius: '5px' }} />
          )}
          
   <Box sx={{ marginTop: 'auto' }}>
  <Button 
    variant="contained" 
    fullWidth
    className="start-button"
    sx={{ 
      paddingY: 2, 
      backgroundColor: online ? '#3366ff' : '#444b5f', 
      '&:hover': { backgroundColor: online ? '#598bff' : '#444b5f' } 
    }}
    onClick={onStartDslrBooth}
    disabled={!online}
  >
    DÉMARRER DSLRBOOTH
  </Button>
  <Button
    component={Link}
    to={`/photobooth/${name}`}
    variant="outlined"
    fullWidth
    sx={{ 
      marginTop: 2, // Ajoute un espacement de 16px (2 * 8px) entre les boutons
      paddingY: 2,
      borderColor: online ? '#3366ff' : '#444b5f',
      color: online ? '#3366ff' : '#444b5f',
      '&:hover': { 
        borderColor: online ? '#598bff' : '#444b5f',
        backgroundColor: 'rgba(51, 102, 255, 0.04)'
      }
    }}
  >
    Voir les détails
  </Button>
</Box>
        </CardContent>
      </Card>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '75%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box 
            component="img"
            src={screenshot}
            alt={`Capture d'écran de ${name}`}
            sx={{ width: '100%', height: 'auto' }}
          />
        </Box>
      </Modal>
    </>
  );
};

export default PhotoboothCard;